import React, { createContext, FC, useState, useContext } from 'react'

import { parse } from 'query-string'

type SessionContext = {
  referralCode: string | null
  setReferralCode: any
}

export const SessionContext = createContext({} as SessionContext)

export const useSessionContext = () => useContext(SessionContext)

export const SessionProvider: FC = props => {
  const [referralCode, setReferralCode] = useState(
    sessionStorage.getItem('fellow-referral-code')
  )
  const parsedQParams = parse(location.search)
  const referralCodeParams = parsedQParams['referral-code']
  const firstReferralCodeParam = Array.isArray(referralCodeParams)
    ? referralCodeParams[0]
    : referralCodeParams
  if (firstReferralCodeParam && firstReferralCodeParam !== referralCode) {
    sessionStorage.setItem('fellow-referral-code', firstReferralCodeParam)
    setReferralCode(sessionStorage.getItem('fellow-referral-code'))
  }

  return (
    <SessionContext.Provider
      {...props}
      value={{
        referralCode,
        setReferralCode,
      }}
    />
  )
}
