import React from 'react' // must be in scope for Context Provider to work
import { SessionProvider } from 'context/session'

require('./static/fonts/AvantGarde/stylesheet.css')
require('./static/fonts/TiemposText/stylesheet.css')
require('./static/fonts/SuisseIntl/stylesheet.css')

export const onRouteUpdate = () => {
  // wrap inside a timeout otherwise scroll doesn't happen
  setTimeout(() => {
    window.scrollTo(0,0)
  }, 0)
  // wrap inside a timeout to ensure the title has properly been changed
  setTimeout(() => {
    if (window.Intercom) {
      window.Intercom('update', {last_request_at: parseInt((new Date()).getTime()/1000)})
    }
    if (window.dataLayer) {
      window.dataLayer.push({ event: `gatsby-route-change` })
    }
  }, 50)

  setTimeout(() => {
    if (window.location.hash) {
      const targetId = window.location.hash.slice(1)
      const targetElement = document.getElementById(targetId)
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" })
      }
    }
  }, 0)
}

export const wrapPageElement = ({ element }) => {
  return (
    <SessionProvider>
      {element}
    </SessionProvider>
  )
}